import SVG from 'react-inlinesvg';
import { Box } from '@gilbarbara/components';

function Logo() {
  return (
    <Box data-component-name="Logo" display="inline-flex">
      <SVG height={42} src="/media/brand/icon.svg" width="auto" />
    </Box>
  );
}

export default Logo;
